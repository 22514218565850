<!-- 添加跟进弹窗 -->
<template>
  <div>
    <el-dialog
        width="50%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        custom-class="ele-dialog-form"
        :title="isUpdate?'催单处理':'催单处理'"
        @update:visible="updateVisible">

      <div slot="title">
        <div style="display: flex;align-items: center;">
          <el-button type="primary" size="small" @click="openScript()">跟进话术</el-button>
          <div class="tianjiagenjin">
            {{isUpdate?'催单处理':'催单处理'}}
          </div>
        </div>
      </div>

      <el-form
          ref="form"
          :model="form"
          label-width="100px">
        <!-- 选项 -->
        <el-row>
          <div class="xuanxianglist">
            <!--  <div class="xuanxianglistactive" v-for="(item,index) in list" :key="index"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">-->
            <!--    <div style="display: flex;align-items: center;justify-content: center;">-->
            <!--      &lt;!&ndash;<img :src="item.img" style="width: 16px;height: 16px;margin-right: 10px;">&ndash;&gt;-->
            <!--      <span>{{item.name}}</span>-->
            <!--    </div>-->
            <!--  </div>-->
            <!--</div>-->
            <!--  <img src="@/assets/images/home/genjindianhua.png" class="phoneicon">-->
            <el-radio-group v-model="telephone_type" @change="open">
              <el-radio :label="item.telephone_type" v-for="(item,index) in list">{{item.name}}</el-radio>
            </el-radio-group>
          </div>
        </el-row>

        <!--<el-row class="topmargin">-->
        <!--  <div style="display: flex;align-items: center;">-->
        <!--    <span class="genjinfont"></span>-->
        <!--    <span class="genjinfont1">下次提醒时间：</span>-->
        <!--    <el-input placeholder="请输入" v-model="form.next_reminder" style='width: 166px !important;'></el-input>-->
        <!--    <span style="margin-left: 10px;">分钟后</span>-->
        <!--  </div>-->
        <!--</el-row>-->

        <div class="marginb">
          <el-button>投诉转发司机</el-button>
          <el-button>投诉转发服务商</el-button>
        </div>

        <el-row class="topmargin">
          <div style="display: flex;align-items: center;">
            <span class="genjinfont"></span>
            <span class="genjinfont1">展示内容</span>
          </div>
          <div style="margin-top: 10px;">
            <div>
              <span>内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
              <span class="leftmargin">
                <el-link type="primary" @click="huifu">回复</el-link>
                 <el-link type="primary" class="leftmargin1" v-if="isedit == true" @click="quxiao">取消</el-link>
              </span>
            </div>
            <el-input
                v-if="isedit == true"
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="form.remark">
            </el-input>
          </div>
        </el-row>

        <el-row class="topmargin">
          <div style="display: flex;align-items: center;">
            <span class="genjinfont"></span>
            <span class="genjinfont1">跟进备注</span>
          </div>
          <div style="margin-top: 10px;">
            <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入收回原因"
                v-model="form.remark">
            </el-input>
          </div>
        </el-row>

        <!--<el-row style="margin-top: 20px;margin-bottom: 20px;">-->
        <!--  <div style="display: flex;align-items: center;">-->
        <!--    <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;"></span>-->
        <!--    <span style="font-size: 16px;font-weight: 500;color: #3f4155;margin-left: 10px;">电话拨打记录</span>-->
        <!--  </div>-->
        <!--  <div style="margin-top: 10px;display: flex;align-items: center;">-->
        <!--    &lt;!&ndash;<div style="font-size: 16px;font-weight: 400;color: #050505;">吴帅丽与救援用户通话</div>&ndash;&gt;-->
        <!--    &lt;!&ndash;<audio&ndash;&gt;-->
        <!--    &lt;!&ndash;    style="margin-left: 20px;"&ndash;&gt;-->
        <!--    &lt;!&ndash;    :src="src"&ndash;&gt;-->
        <!--    &lt;!&ndash;    autoplay="autoplay"&ndash;&gt;-->
        <!--    &lt;!&ndash;    controls="controls"&ndash;&gt;-->
        <!--    &lt;!&ndash;    ref="audio"&ndash;&gt;-->
        <!--    &lt;!&ndash;&gt;Your browser does not support the audio element.</audio>&ndash;&gt;-->
        <!--    <el-upload-->
        <!--        class="upload-demo"-->
        <!--        :action="action"-->
        <!--        :headers="headers"-->
        <!--        :on-preview="handlePreview"-->
        <!--        :on-remove="handleRemove"-->
        <!--        :before-remove="beforeRemove"-->
        <!--        :on-success="successUpload"-->
        <!--        multiple-->
        <!--        :limit="1"-->
        <!--        :on-exceed="handleExceed">-->
        <!--      <div class="shangchuan" style="margin-left: 20px">-->
        <!--        <el-button class="custom-button">-->
        <!--          <i class="el-icon-shangchuan" />-->
        <!--          <span style="vertical-align: middle">点击上传</span>-->
        <!--        </el-button>-->
        <!--      </div>-->
        <!--    </el-upload>-->
        <!--  </div>-->
        <!--</el-row>-->
      </el-form>

      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            v-auths="[`${$config.uniquePrefix}orderModule:info:followup`]"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="拨打电话"
        center
        :visible.sync="drawer"
        :show-close="false"
        width="30%"
        :before-close="handleClose">
        <div class="paddingbianju">
          <el-radio-group v-model="radio">
            <div class="xiabianju">
              <el-radio :label="1">小明 13103000257</el-radio>
            </div>
            <div class="xiabianju">
              <el-radio :label="2">小红 13103000257</el-radio>
            </div>
            <div class="xiabianju">
              <el-radio :label="3">小黑 13103000257</el-radio>
            </div>
          </el-radio-group>
        </div>
      <div slot="footer">
        <el-button class="rightmargin" @click="drawer = false">
          取消
        </el-button>
        <el-button type="primary" @click="queding" class="rightmargin">
          确定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies, setCookies} from '@/utils/cookies'
import Config from '@/config'
// 引入的接口
import {order_follow} from '@/api/orderModule'
// 引入的接口
import {get_tel_no} from '@/api/phone'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //司机相关
      list:[
        {
          img: require('../../../../assets/images/home/genjindianhuaactive.png'),
          name:'拨打车主电话',
          telephone_type:'owner'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打订单来源客户电话',
          telephone_type:'upstream'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打司机电话',
          telephone_type:'technician'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打服务商电话',
          telephone_type:'facilitator'
        }
      ],
      isShow:0,

      telephone_type:'owner',

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/order',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      src:'',

      // 电话抽屉
      drawer: false,
      direction: 'rtl',

      radio:1,

      //回复
      isedit:false

    };
  },


  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.form.remark = '';
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },

  methods: {
    //上传事件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      // 上传成功后的列表
      this.form.phone_url = file.data.urls;
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            odd: this.data.order_id,
            telephone_type: this.telephone_type,
            next_reminder: this.form.next_reminder,
            remark: this.form.remark,
            phone_url: this.form.phone_url
          }
          order_follow(this.data.id,data).then(res => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success(res.msg);
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done');
              this.loading = false;
            } else {
              this.$message.error(res.msg);
            }
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      this.telephone_type = item.telephone_type;
      if(item.name == '车主电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打订单来源客户电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打司机电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打服务商电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhuaactive.png');
      }

    },


    open(){
      this.drawer = true;
    },

    handleClose() {
      this.drawer = false;
    },
    queding(){
      this.tel();
    },

    // 电话话术
    openScript(){
      let showScript = true;
      this.$store.dispatch('theme/setshowScript', showScript).then(() => {
        console.log('打开成功')
        console.log(this.$store.state.theme.showScript,'全局话术')
      }).catch((e) => {
        console.error(e)
        this.$message.error('打开失败')
      })
    },

    // 点击拨打电话
    tel(phone,tel_type){
      get_tel_no().then(res => {
        if(res.data.is_exist == 0){

          // 显示拨打电话弹窗
          let data = {
            showPhone: true
          }
          this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
          // 要拨打的手机号
          let dianhua = {
            phone: phone
          }
          this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

          // 获取 订单号id
          let orderid = {
            orderid: this.data.id,
          }
          this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

          // 获取呼出类型
          let teltype = {
            tel_type: tel_type
          }
          this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
        }else {
          this.$message.error('暂无坐席')
        }
      })
    },

    huifu(){
      this.isedit = true;
    },
    quxiao(){
      this.isedit = false;
    },

  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .xuanxianglistactive{
    width: 23%;
    cursor: pointer;
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
    text-align: center;
    margin-top: 0;
    margin-right: 10px;
    display: inline-block;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    color: #FFFFFF;
    border-color: #FF9B05;
  }

  //最后一个
  .xuanxianglistactive:last-child{
    margin-right: 0;
  }

}

.paddingbianju{
  //padding: 20px;
}

.phoneicon{
  width: 16px;height: 16px;margin-left: 10px;
}
.xiabianju{
  margin: 20px;
}
.rightmargin{
  margin-right: 20px;
}


.tianjiagenjin{
  width: 670px;
  font-size: 18px;
}

.topmargin{
  margin-top: 20px;
}
.genjinfont{
  width: 5px;height: 16px;background: #a3a4b2;display: inline-block;
}
.genjinfont1{
  font-size: 16px;font-weight: 500;color: #3f4155;margin-left: 10px;
}
.leftmargin{
  margin-left: 20px;
}
.leftmargin1{
  margin-left: 15px;
}
.marginb{
  margin-top: 10px;
}
</style>
